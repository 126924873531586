import React, { useState } from "react";
import { MenuHeader } from "../MenuHeader";
import Background from "../../assets/Back.png";
import OtpInput from "react-otp-input";

export const Pin = () => {
	const [otp, setOtp] = useState("");
	const [confirmOtp, setConfirmOtp] = useState("");
	return (
		<div className=''>
			<div className=' h-screen'>
				<MenuHeader check='Login' status />
				<div className='  bg-[#f9f9f9]'>
					<main
						className='h-screen'
						style={{
							backgroundImage: `url(${Background})`,
							backgroundRepeat: "no-repeat",
							backgroundPosition: "right",
							backgroundSize: "50% 100%",
						}}
					>
						<section className='py-2 px-10'>
							<div className='mb-4'>
								<p className='inline-block p-2 bg-[#e9f4ec] text-xs rounded-md'>
									Please be sure you're are at{" "}
									<span className='underline'>https://loripay.com</span>
								</p>
							</div>
							<form
								action=''
								className='bg-white rounded-sm px-8 py-3 inline-block'
							>
								<h2 className='font-bold mb-4'>
									Set Your Preferred Transaction Pin
								</h2>
								<p className='text-[14px]'>Enter Pin</p>
								<div className='my-4'>
									<OtpInput
										value={otp}
										onChange={setOtp}
										numInputs={4}
										// renderSeparator={<span>-</span>}
										renderInput={(props) => <input {...props} />}
										inputStyle={{
											height: "40px",
											width: "35px",
											borderRadius: "5px",
											marginLeft: "5px",
											marginRight: "5px",
											border: "0.5px #ccc solid",
											backgroundColor: "#f9f9f9",
										}}
									/>
								</div>
								<p className='text-[14px]'>Confirm Pin</p>
								<div className='my-4'>
									<OtpInput
										value={confirmOtp}
										onChange={setConfirmOtp}
										numInputs={4}
										// renderSeparator={<span>-</span>}
										renderInput={(props) => <input {...props} />}
										inputStyle={{
											height: "40px",
											width: "35px",
											borderRadius: "5px",
											marginLeft: "5px",
											marginRight: "5px",
											border: "0.5px #ccc solid",
											backgroundColor: "#f9f9f9",
										}}
									/>
								</div>
								<button className='text-right block mr-0 ml-auto py-1 px-6 bg-green-500 rounded-sm text-xs mt-2'>
									Submit
								</button>
							</form>
						</section>
					</main>
				</div>
			</div>
		</div>
	);
};
