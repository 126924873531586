import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Bill } from "../products/Bill";
import { PayHeader } from "../layout/PayHeader";

export const Pay = () => {
	const [itemToShow, setItemToShow] = useState("");

	const location = useLocation();

	useEffect(() => {
		setItemToShow(location.state);
	}, [location]);
	return (
		<div className='flex justify-center items-center flex-col'>
			<PayHeader itemToShow={itemToShow} />

			<section>
				{itemToShow === "Bill" && <Bill itemToShow={itemToShow} />}
			</section>
		</div>
	);
};
