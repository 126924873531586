import React from "react";
import Logo from "../assets/Logo 2.png";

export const Powered = () => {
	return (
		<div className='flex  items-center'>
			<img src={Logo} alt='' className='w-20 h-12' />
			<p>Powered by Lorisic</p>
		</div>
	);
};
