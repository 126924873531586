import React, { BaseSyntheticEvent, ChangeEvent, useState } from "react";
import Nigeria from "../../assets/icons/019-nigeria-1.png";
import { PayHeader } from "../layout/PayHeader";
import OtpInput from "react-otp-input";

export interface ElectricityInterface {
	itemToShow: string;
}

export const Electricity: React.FC<ElectricityInterface> = ({ itemToShow }) => {
	const [form, setForm] = useState({
		meterNumber: "",
		amount: "",
		package: "",
		provider: "",
	});
	const [pin, setPin] = useState("");

	const [showCoonfirm, setShowConfirm] = useState(false);

	const onChange = (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
		const { name, value } = e.target;

		setForm({ ...form, [name]: value });
	};

	const handleSubmit = async (e: BaseSyntheticEvent) => {
		e.preventDefault();
		setShowConfirm(true);
	};

	return (
		<div className='z-0 relative'>
			<PayHeader itemToShow={itemToShow} />

			<div className='flex justify-center items-center flex-col'>
				<main className='my-6  w-96 p-8 bg-white shadow-xl px-8 py-6 rounded-md'>
					<section>
						<div className='text-center flex justify-center items-center mb-8'>
							<h2>Electricity</h2>
							<img src={Nigeria} className='w-4 h-4 mt-[-10px]' alt='' />
						</div>
					</section>
					<section>
						<form action='' onSubmit={handleSubmit}>
							<div>
								<label htmlFor='provider' className='text-xs   block'>
									Service Provider
								</label>
								<select
									name='provider'
									id='provider'
									required
									value={form.provider}
									onChange={onChange}
									className='block my-2 text-sm text-black rounded-md  border-[#ccc] border-[0.2px] w-full p-2 bg-gray-300 outline-none focus:outline-none appearance-none'
								>
									<option value=''>Select Disco</option>
									<option value='IKEDC'>IKEDC</option>
									<option value='PHEDC'>PHEDC</option>
									<option value='AEDC'>AEDC</option>
									<option value='EEDC'>EEDC</option>
									<option value='IBEDC'>IBEDC</option>
									<option value='KAEDC'>KAEDC</option>
									<option value='KEDC'>KEDC</option>
								</select>
							</div>
							<div>
								<label htmlFor='package' className='text-xs   block'>
									Package
								</label>
								<select
									name='package'
									id='package'
									required
									value={form.package}
									onChange={onChange}
									className='block my-2 text-sm text-black rounded-md  border-[#ccc] border-[0.2px] w-full p-2 bg-gray-300 outline-none focus:outline-none appearance-none'
								>
									<option value='PREPAID'>PREPAID</option>
									<option value='POSTPAID'>POSTPAID</option>
								</select>
							</div>
							<div>
								<label htmlFor='meterNumber' className='text-xs   block'>
									Meter Number
								</label>
								<input
									type='number'
									name='meterNumber'
									onChange={onChange}
									value={form.meterNumber}
									required
									id='meterNumber'
									placeholder='meter number'
									className='block my-2 text-sm text-black rounded-md  border-[#ccc] border-[0.2px] w-full p-2 bg-gray-300 outline-none focus:outline-none appearance-none'
								/>
							</div>
							<div>
								<label htmlFor='amount' className='text-xs   block'>
									Amount
								</label>
								<input
									type='number'
									placeholder='Amount'
									value={form.amount}
									onChange={onChange}
									required
									name='amount'
									id='amount'
									className='block my-2 text-sm text-black rounded-md  border-[#ccc] border-[0.2px] w-full p-2 bg-gray-300 outline-none focus:outline-none appearance-none'
								/>
							</div>
							<div className='text-right my-2'>
								<button className='px-8 py-2 bg-green-500 rounded-md'>
									Next
								</button>
							</div>
						</form>
					</section>
				</main>
			</div>
			{showCoonfirm && (
				<div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-12 py-6 bg-white rounded-md w-[400px]'>
					<h2 className='text-center text-3xl font-medium'>Confirm</h2>
					<div className='my-6 pb-3 flex justify-between items-center border-b-[1px] border-[#ccc]'>
						<p>Meter Number:</p>
						<p>{form.meterNumber}</p>
					</div>
					<div className='my-6 pb-3 flex justify-between items-center border-b-[1px] border-[#ccc]'>
						<p>Amount:</p>
						<p>{form.amount}</p>
					</div>
					<div className='my-6 pb-3 flex justify-between items-center border-b-[1px] border-[#ccc]'>
						<p>Address:</p>
						<p>No 32 Shittu Lane</p>
					</div>
					<div className='my-6 pb-3 flex justify-between items-center border-b-[1px] border-[#ccc]'>
						<p>Service Provider:</p>
						<p>
							{form.provider} {form.package}
						</p>
					</div>
					<div className='my-6'>
						<p>Please Input your transaction Pin</p>
						<div className='my-6 flex justify-center items-center'>
							<OtpInput
								value={pin}
								onChange={setPin}
								numInputs={4}
								// renderSeparator={<span>-</span>}
								renderInput={(props) => <input {...props} />}
								inputStyle={{
									height: "50px",
									width: "40px",
									borderRadius: "5px",
									marginLeft: "5px",
									marginRight: "5px",
									border: "0.5px #ccc solid",
									backgroundColor: "#efefef",
								}}
							/>
						</div>
						<div className='flex items-center justify-between'>
							<button
								className='px-8 py-2 bg-gray-300 rounded-md'
								onClick={() => setShowConfirm(false)}
							>
								Cancel
							</button>
							<button className='px-8 py-2 bg-green-500 rounded-md'>
								Confirm
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
