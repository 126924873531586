import Airtime from "../assets/icons/008-phone-call.png";
import Cable from "../assets/icons/002-tv-monitor-1.png";
import Bet from "../assets/icons/004-bet.png";
import Data from "../assets/icons/005-wifi.png";
import Menu from "../assets/icons/010-menu.png";
import Internet from "../assets/icons/011-wifi-router.png";
import Flight from "../assets/icons/direct-flight.png";
import Electricity from "../assets/icons/003-backup.png";
// import Money from "../assets/icons/015-naira-1.png";

export const Icons = [
	{
		title: "Airtime",
		Icon: Airtime,
		link: "/dashboard/pay",
		state: "Telco",
		display: true,
	},
	{
		title: "Data",
		Icon: Data,
		link: "/dashboard/pay",
		display: true,
		state: "Telco",
	},
	{
		title: "Cable Subscription",
		Icon: Cable,
		link: "/dashboard/pay",
		display: true,
		state: "Bill",
	},
	{
		title: "Electricity",
		Icon: Electricity,
		link: "/dashboard/pay",
		display: true,
		state: "Bill",
	},
	{
		title: "Lottery Games",
		Icon: Bet,
		link: "/dashboard/pay",
		display: true,
		state: "Bet",
	},
	{
		title: "Internet Subscription",
		Icon: Internet,
		link: "/dashboard/pay",
		display: true,
		state: "Bill",
	},
	{
		title: "Flight",
		Icon: Flight,
		link: "/dashboard/pay",
		display: true,
		state: "Flight",
	},
	{
		title: "Others",
		Icon: Menu,
		link: "/dashboard/pay",
		display: true,
		state: "Others",
	},
];

export const SidebarList = [
	{
		title: "Dashboard",
		Icon: "fa-solid fa-chart-line",
		element: true,
	},
	{ title: "Transactions", Icon: "fa-solid fa-cart-shopping", element: true },
	{
		title: "Payments",
		Icon: "fa-solid fa-credit-card",
		element: true,
	},
	{
		title: "Deposit",
		Icon: "fa-solid fa-percent",
		element: true,
	},
	{
		title: "MoneyBox",
		Icon: "fa-solid fa-sack-dollar",
		element: true,
	},
	{
		title: "Securities",
		Icon: "fa-solid fa-shield-halved",
		element: true,
	},
	{
		title: "Help",
		Icon: "fa-solid fa-circle-info",
		element: true,
	},
	{
		title: "Settings",
		Icon: "fa-solid fa-gear",
		element: true,
	},
];
