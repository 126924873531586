import React from "react";
import logo from "../assets/LOGO.png";
import { Link } from "react-router-dom";
import Nigeria from "../assets/nigeria.png";
import Ghana from "../assets/ghana.png";

export interface MenuHeaderInterface {
	check: string;
	country?: string;
	image?: string;
	status?: boolean;
	onChange?: () => void;
	menuName?: string;
}

export const MenuHeader: React.FC<MenuHeaderInterface> = ({
	check,
	country,
	image,
	status,
	onChange,
	menuName,
}) => {
	return (
		<section className='bg-[#e9f4ec]'>
			<div className='px-6 py-2 border-b-2'>
				<header className='flex lg:justify-between items-center'>
					{status && (
						<img
							src={logo}
							alt='logipay-logo'
							className='h-10 w-20 cursor-pointer'
							onClick={onChange}
						/>
					)}
					{status ? (
						<ul className='flex lg:justify-center items-center'>
							{check === "Home" && (
								<>
									<li className='lg:mr-5 mr-1'>
										<Link to='/login'>Sign In</Link>
									</li>
									<li className='lg:mr-5 mr-1'>
										<Link to='/register'>Create An Account </Link>
									</li>
									<li className='mr-2 ml-2'>
										<img
											src={Ghana}
											className='rounded-full w-6 h-6'
											alt='ghanian-flag'
										/>
									</li>
									<li className='mr-2 ml-2'>
										<img
											src={Nigeria}
											className='rounded-full w-6 h-6'
											alt='nigerian-flag'
										/>
									</li>
								</>
							)}
							{check === "Login" && (
								<>
									<li className='lg:mr-5 mr-2'>
										<Link to='/register'>Create An Account </Link>
									</li>
									<li className='mr-2 ml-2'>
										<img
											src={Ghana}
											className='rounded-full w-6 h-6'
											alt='ghanian-flag'
										/>
									</li>
									<li className='mr-2 ml-2'>
										<img
											src={Nigeria}
											className='rounded-full w-6 h-6'
											alt='nigerian-flag'
										/>
									</li>
								</>
							)}
							{check === "Register" && (
								<>
									<li className='lg:mr-5 mr-2'>
										<Link to='/login'>Sign In</Link>
									</li>
									<li className='mr-2 ml-2'>
										<img
											src={Ghana}
											className='rounded-full w-6 h-6'
											alt='ghanian-flag'
										/>
									</li>
									<li className='mr-2 ml-2'>
										<img
											src={Nigeria}
											className='rounded-full w-6 h-6'
											alt='nigerian-flag'
										/>
									</li>
								</>
							)}
							{check === "Dashboard" && (
								<>
									<li className='mr-2 ml-2'>
										<p className='font-bold'>Hello, Slim</p>
									</li>
									<li>
										<img src={image} className='rounded-full w-6 h-6' alt='' />
									</li>
									{country === "Ghana" ? (
										<li className='mr-2 ml-2'>
											<img
												src={Ghana}
												className='rounded-full w-6 h-6'
												alt='ghanian-flag'
											/>
										</li>
									) : (
										<li className='mr-2 ml-2'>
											<img
												src={Nigeria}
												className='rounded-full w-6 h-6'
												alt='nigerian-flag'
											/>
										</li>
									)}
								</>
							)}
						</ul>
					) : (
						<ul className='flex items-center justify-between w-full'>
							<li onClick={onChange} className='cursor-pointer'>
								{menuName}
							</li>
							{country === "Ghana" ? (
								<li className='mr-2 ml-2'>
									<img
										src={Ghana}
										className='rounded-full w-6 h-6'
										alt='ghanian-flag'
									/>
								</li>
							) : (
								<li className='mr-2 ml-2'>
									<img
										src={Nigeria}
										className='rounded-full w-6 h-6'
										alt='nigerian-flag'
									/>
								</li>
							)}
						</ul>
					)}
				</header>
			</div>
		</section>
	);
};
