import React, { useEffect } from "react";
import { useMenu } from "./DashboardMenu";

import CreditCard from "../../assets/icons/005-credit-card-1.png";

export const Money = () => {
	const { setMenuName } = useMenu();

	useEffect(() => {
		setMenuName("Money");
	});
	return (
		<div>
			<section>
				<div className='bg-white px-20 rounded-md py-6 '>
					<h2 className='font-extrabold text-center'>Add with your card</h2>
					<div className='rounded-md px-6 py-4 shadow-2xl my-6'>
						<div className='flex justify-center items-center'>
							<div className='rounded-full p-4 bg-green-200  flex justify-center items-center'>
								<img src={CreditCard} alt='' className='w-10 h-10' />
							</div>
						</div>
						<p className='text-center text-gray-300 mt-1  '>
							Charges Applies for all card Top-Ups
						</p>
						<div className='mt-10 flex justify-between items-center pb-3  border-b-[0.5px] border-gray-400'>
							<span className='text-gray-300'>Naira Card</span>
							<span>N100 + 1.5% of Amount</span>
						</div>
						<div className='my-4'>
							<p className='font-extrabold my-1'>Saved Cards</p>
							<p className='text-gray-300'>
								You do not have any currently saved cards
							</p>
						</div>
						<div className=' text-right mt-10'>
							<button className='bg-green-600 rounded-md px-6 py-1'>
								Add New Card
							</button>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
