import React, { useEffect, useState } from "react";
import { MenuHeader } from "../MenuHeader";
// import Payment from "../../assets/8367360.jpg";
import NigerianFlag from "../../assets/nigeria-flag.png";
import GhanianFlag from "../../assets/ghana-flag.png";
import Image from "../../assets/compressed.jpeg";
import "./register.css";

export const Register = () => {
	const [flag, setFlag] = useState("Nigeria");
	const [passwordText, setPasswordText] = useState(false);
	const [confirmPasswordText, setConfirmPasswordText] = useState(false);

	const [backgroundSize, setBackgroundSize] = useState("50% 100%");

	const handleResize = () => {
		// Check the screen width and set the backgroundSize accordingly
		if (window.innerWidth < 850) {
			setBackgroundSize("100% 100%");
		} else {
			setBackgroundSize("50% 100%");
		}
	};

	useEffect(() => {
		// Add a listener for the window resize event
		window.addEventListener("resize", handleResize);
		// Initial check for the screen size
		handleResize();

		// Clean up the listener when the component unmounts
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className='z-0 relative'>
			<div className=' h-screen'>
				<MenuHeader check='Login' status />
				<div className='  bg-[#f9f9f9]'>
					<main
						className=' lg:h-screen lg:p-0 px-6'
						style={{
							backgroundImage: `linear-gradient(to right, rgba(195, 195,195,0.3), rgba(195,195,195,0.3)),url(${Image})`,
							backgroundRepeat: "no-repeat",
							backgroundPosition: "right",
							backgroundSize: backgroundSize,
							opacity: 0.8,
						}}
					>
						<div className='px-8 py-4'>
							<div>
								<p className='inline-block p-2 bg-[#e9f4ec] rounded-md'>
									Please be sure you're are at{" "}
									<span className='underline'>https://loripay.com</span>
								</p>
							</div>
							<div className='bg-white inline-block my-4 p-8 rounded-md'>
								<h2 className='font-bold text-lg my-2'>Sign Up to Loripay</h2>
								<p>Select Your Country</p>
								<div>
									<img
										src={NigerianFlag}
										alt=''
										className={
											flag === "Nigeria"
												? "my-4 w-40 h-20 p-2 bg-[#d3cece] cursor-pointer"
												: "my-4 w-40 h-20 p-2 cursor-pointer"
										}
										onClick={() => setFlag("Nigeria")}
									/>
									<img
										src={GhanianFlag}
										alt=''
										className={
											flag === "Ghana"
												? "my-4 w-40 h-20 p-2 bg-[#d3cece] cursor-pointer"
												: "my-4 w-40 h-20 p-2 cursor-pointer"
										}
										onClick={() => setFlag("Ghana")}
									/>
								</div>
							</div>
						</div>
						<div className='lg:block hidden z-10 bg-white px-16 py-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[400px] rounded-md'>
							<form action='' autoComplete='off'>
								<h2 className='font-bold text-[25px] mb-4'>Create Account</h2>
								<div className='my-1'>
									<label
										htmlFor='username'
										className='block my-1 text-gray-600 text-sm'
									>
										Username
									</label>
									<input
										type='text'
										placeholder='username'
										className='w-full px-2 py-2 bg-gray-100 focus:outline-none outline-none my-1 rounded-sm'
									/>
								</div>
								<div className='w-full flex justify-between my-1'>
									<div>
										<label
											htmlFor='firstname'
											className='block my-1 text-gray-600 text-sm'
										>
											First Name
										</label>
										<input
											type='text'
											placeholder='First Name'
											className='w-full px-2 py-2 bg-gray-100 focus:outline-none outline-none my-1 rounded-sm mr-2'
										/>
									</div>
									<div>
										<label
											htmlFor='lastname'
											className='block my-1 text-gray-600 text-sm'
										>
											Last Name
										</label>
										<input
											type='text'
											placeholder='Last Name'
											className='px-2 w-full py-2 bg-gray-100 focus:outline-none outline-none my-1 rounded-sm ml-2'
										/>
									</div>
								</div>
								<div className='w-full my-1'>
									<label
										htmlFor='phone-number'
										className='block my-1 text-gray-600 text-sm'
									>
										Phone
									</label>
									<input
										type='number'
										placeholder='phone-number'
										className='w-full px-2 py-2 bg-gray-100 focus:outline-none outline-none my-1 rounded-sm'
									/>
								</div>
								<div className='w-full my-1'>
									<label
										htmlFor='password'
										className='block my-1 text-gray-600 text-sm'
									>
										Password
									</label>
									<div className='bg-gray-100 px-2 py-2'>
										<input
											type={passwordText ? "text" : "password"}
											placeholder='password'
											className='w-[90%]  border-none bg-gray-100 focus:outline-none outline-none my-1 rounded-sm'
											autoComplete='off'
										/>
										<i
											className='fa-regular fa-eye text-sm cursor-pointer'
											onClick={() => setPasswordText(!passwordText)}
										></i>
									</div>
								</div>
								<div className='w-full my-1'>
									<label
										htmlFor='confirm-password'
										className='block text-gray-600 my-1 text-sm'
									>
										Confirm Password
									</label>
									<div className='bg-gray-100 px-2 py-2'>
										<input
											type={confirmPasswordText ? "text" : "password"}
											placeholder='confirm-password'
											className='w-[90%]  border-none bg-gray-100 focus:outline-none outline-none my-1 rounded-sm'
											autoComplete='off'
										/>
										<i
											className='fa-regular fa-eye text-sm cursor-pointer'
											onClick={() =>
												setConfirmPasswordText(!confirmPasswordText)
											}
										></i>
									</div>
									<button className='text-right block mr-0 ml-auto py-1 px-6 bg-green-500 rounded-sm text-xs my-6'>
										Let's go
									</button>
								</div>
							</form>
						</div>
						<div className='lg:hidden block z-10 bg-white px-16 py-4 '>
							<form action='' autoComplete='off'>
								<h2 className='font-bold text-[25px] mb-4'>Create Account</h2>
								<div className='my-1'>
									<label
										htmlFor='username'
										className='block my-1 text-gray-600 text-sm'
									>
										Username
									</label>
									<input
										type='text'
										placeholder='username'
										className='w-full px-2 py-2 bg-gray-100 focus:outline-none outline-none my-1 rounded-sm'
									/>
								</div>
								<div className='w-full flex justify-between my-1'>
									<div>
										<label
											htmlFor='firstname'
											className='block my-1 text-gray-600 text-sm'
										>
											First Name
										</label>
										<input
											type='text'
											placeholder='First Name'
											className='w-full px-2 py-2 bg-gray-100 focus:outline-none outline-none my-1 rounded-sm mr-2'
										/>
									</div>
									<div>
										<label
											htmlFor='lastname'
											className='block my-1 text-gray-600 text-sm'
										>
											Last Name
										</label>
										<input
											type='text'
											placeholder='Last Name'
											className='px-2 w-full py-2 bg-gray-100 focus:outline-none outline-none my-1 rounded-sm ml-2'
										/>
									</div>
								</div>
								<div className='w-full my-1'>
									<label
										htmlFor='phone-number'
										className='block my-1 text-gray-600 text-sm'
									>
										Phone
									</label>
									<input
										type='number'
										placeholder='phone-number'
										className='w-full px-2 py-2 bg-gray-100 focus:outline-none outline-none my-1 rounded-sm'
									/>
								</div>
								<div className='w-full my-1'>
									<label
										htmlFor='password'
										className='block my-1 text-gray-600 text-sm'
									>
										Password
									</label>
									<div className='bg-gray-100 px-2 py-2'>
										<input
											type={passwordText ? "text" : "password"}
											placeholder='password'
											className='w-[90%]  border-none bg-gray-100 focus:outline-none outline-none my-1 rounded-sm'
											autoComplete='off'
										/>
										<i
											className='fa-regular fa-eye text-sm cursor-pointer'
											onClick={() => setPasswordText(!passwordText)}
										></i>
									</div>
								</div>
								<div className='w-full my-1'>
									<label
										htmlFor='confirm-password'
										className='block text-gray-600 my-1 text-sm'
									>
										Confirm Password
									</label>
									<div className='bg-gray-100 px-2 py-2'>
										<input
											type={confirmPasswordText ? "text" : "password"}
											placeholder='confirm-password'
											className='w-[90%]  border-none bg-gray-100 focus:outline-none outline-none my-1 rounded-sm'
											autoComplete='off'
										/>
										<i
											className='fa-regular fa-eye text-sm cursor-pointer'
											onClick={() =>
												setConfirmPasswordText(!confirmPasswordText)
											}
										></i>
									</div>
									<button className='text-right block mr-0 ml-auto py-1 px-6 bg-green-500 rounded-sm text-xs my-6'>
										Let's go
									</button>
								</div>
							</form>
						</div>
					</main>
				</div>
			</div>
			{/* <div className='flex justify-between items-center'>
					<section className='flex-1 p-6'>
						<p className='inline-block p-2 bg-[#e9f4ec] text-xs rounded-md'>
							Please be sure you're are at{" "}
							<span className='underline'>https://loripay.com</span>
						</p>
						<div className='bg-[#f9f9f9] w-2/4 my-4 p-8 rounded-md'>
							<h2 className='font-bold text-lg my-2'>Sign Up to Loripay</h2>
							<p>Select Your Country</p>
							<div>
								<img
									src={NigerianFlag}
									alt=''
									className={
										flag === "Nigeria"
											? "my-4 w-40 h-20 p-2 bg-[#d3cece] cursor-pointer"
											: "my-4 w-40 h-20 p-2 cursor-pointer"
									}
									onClick={() => setFlag("Nigeria")}
								/>
								<img
									src={GhanianFlag}
									alt=''
									className={
										flag === "Ghana"
											? "my-4 w-40 h-20 p-2 bg-[#d3cece] cursor-pointer"
											: "my-4 w-40 h-20 p-2 cursor-pointer"
									}
									onClick={() => setFlag("Ghana")}
								/>
							</div>
						</div>
					</section>
					<section className='flex-1'>
						<img src={Payment} alt='' className='lg:h-[500px] rounded-full' />
					</section>
				</div>
			</div>
			<div className='z-10 bg-[#f9f9f9] px-16 py-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[400px]'>
				<form action='' autoComplete='off'>
					<h2 className='font-bold text-[25px] mb-4'>Create Account</h2>
					<div className='my-1'>
						<label
							htmlFor='username'
							className='block my-1 text-gray-600 text-sm'
						>
							Username
						</label>
						<input
							type='text'
							placeholder='username'
							className='w-full px-2 py-2 bg-gray-100 focus:outline-none outline-none my-1 rounded-sm'
						/>
					</div>
					<div className='w-full flex justify-between my-1'>
						<div>
							<label
								htmlFor='firstname'
								className='block my-1 text-gray-600 text-sm'
							>
								First Name
							</label>
							<input
								type='text'
								placeholder='First Name'
								className='w-full px-2 py-2 bg-gray-100 focus:outline-none outline-none my-1 rounded-sm mr-2'
							/>
						</div>
						<div>
							<label
								htmlFor='lastname'
								className='block my-1 text-gray-600 text-sm'
							>
								Last Name
							</label>
							<input
								type='text'
								placeholder='Last Name'
								className='px-2 w-full py-2 bg-gray-100 focus:outline-none outline-none my-1 rounded-sm ml-2'
							/>
						</div>
					</div>
					<div className='w-full my-1'>
						<label
							htmlFor='phone-number'
							className='block my-1 text-gray-600 text-sm'
						>
							Phone
						</label>
						<input
							type='number'
							placeholder='phone-number'
							className='w-full px-2 py-2 bg-gray-100 focus:outline-none outline-none my-1 rounded-sm'
						/>
					</div>
					<div className='w-full my-1'>
						<label
							htmlFor='password'
							className='block my-1 text-gray-600 text-sm'
						>
							Password
						</label>
						<div className='bg-gray-100 px-2 py-2'>
							<input
								type={passwordText ? "text" : "password"}
								placeholder='password'
								className='w-[90%]  border-none bg-gray-100 focus:outline-none outline-none my-1 rounded-sm'
								autoComplete='off'
							/>
							<i
								className='fa-regular fa-eye text-sm cursor-pointer'
								onClick={() => setPasswordText(!passwordText)}
							></i>
						</div>
					</div>
					<div className='w-full my-1'>
						<label
							htmlFor='confirm-password'
							className='block text-gray-600 my-1 text-sm'
						>
							Confirm Password
						</label>
						<div className='bg-gray-100 px-2 py-2'>
							<input
								type={confirmPasswordText ? "text" : "password"}
								placeholder='confirm-password'
								className='w-[90%]  border-none bg-gray-100 focus:outline-none outline-none my-1 rounded-sm'
								autoComplete='off'
							/>
							<i
								className='fa-regular fa-eye text-sm cursor-pointer'
								onClick={() => setConfirmPasswordText(!confirmPasswordText)}
							></i>
						</div>
					</div>
				</form>
			</div> */}
		</div>
	);
};

export const item = [
	{
		availability: null,
		price: 330,
		name: " 1GB FlexiDaily",
		allowance: " 1GB FlexiDaily",
		validity: "1",
		code: "624",
	},
	{
		availability: null,
		price: 550,
		name: " 2.5GB FlexiDaily",
		allowance: " 2.5GB FlexiDaily",
		validity: "2",
		code: "625",
	},
	{
		availability: null,
		price: 550,
		name: "1GB FlexiWeekly",
		allowance: "1GB FlexiWeekly",
		validity: "7",
		code: "626",
	},
	{
		availability: null,
		price: 550,
		name: "International SmileVoice ONLY 60",
		allowance: "International SmileVoice ONLY 60",
		validity: "7",
		code: "509",
	},
	{
		availability: null,
		price: 600,
		name: "SmileVoice ONLY 65",
		allowance: "SmileVoice ONLY 65",
		validity: "30",
		code: "747",
	},
	{
		availability: null,
		price: 1100,
		name: "1.5GB Bigga",
		allowance: "1.5GB Bigga",
		validity: "30",
		code: "606",
	},
	{
		availability: null,
		price: 1100,
		name: " 2GB FlexiWeekly ",
		allowance: " 2GB FlexiWeekly ",
		validity: "7",
		code: "627",
	},
	{
		availability: null,
		price: 1100,
		name: "International SmileVoice ONLY 125",
		allowance: "International SmileVoice ONLY 125",
		validity: "30",
		code: "510",
	},
	{
		availability: null,
		price: 1250,
		name: "SmileVoice ONLY 135",
		allowance: "SmileVoice ONLY 135",
		validity: "30",
		code: "748",
	},
	{
		availability: null,
		price: 1320,
		name: "2GB Bigga",
		allowance: "2GB Bigga",
		validity: "30",
		code: "607",
	},
	{
		availability: null,
		price: 1650,
		name: "3GB Bigga",
		allowance: "3GB Bigga",
		validity: "30",
		code: "608",
	},
	{
		availability: null,
		price: 1650,
		name: " 6GB FlexiWeekly ",
		allowance: " 6GB FlexiWeekly ",
		validity: "7",
		code: "628",
	},
	{
		availability: null,
		price: 1800,
		name: "SmileVoice ONLY 150",
		allowance: "SmileVoice ONLY 150",
		validity: "60",
		code: "750",
	},
	{
		availability: null,
		price: 2200,
		name: "5GB Bigga",
		allowance: "5GB Bigga",
		validity: "30",
		code: "620",
	},
	{
		availability: null,
		price: 2400,
		name: "SmileVoice ONLY 175",
		allowance: "SmileVoice ONLY 175",
		validity: "90",
		code: "752",
	},
	{
		availability: null,
		price: 2750,
		name: "6.5GB Bigga",
		allowance: "6.5GB Bigga",
		validity: "30",
		code: "609",
	},
	{
		availability: null,
		price: 3300,
		name: "10GB Bigga",
		allowance: "10GB Bigga",
		validity: "30",
		code: "722",
	},
	{
		availability: null,
		price: 3850,
		name: "SmileVoice ONLY 430",
		allowance: "SmileVoice ONLY 430",
		validity: "30",
		code: "749",
	},
	{
		availability: null,
		price: 4400,
		name: "15GB Bigga",
		allowance: "15GB Bigga",
		validity: "30",
		code: "723",
	},
	{
		availability: null,
		price: 4850,
		name: "SmileVoice ONLY 450",
		allowance: "SmileVoice ONLY 450",
		validity: "60",
		code: "751",
	},
	{
		availability: null,
		price: 5000,
		name: "Freedom Mobile Plan",
		allowance: "Freedom Mobile Plan",
		validity: "30",
		code: "758",
	},
	{
		availability: null,
		price: 5500,
		name: "20GB Bigga",
		allowance: "20GB Bigga",
		validity: "30",
		code: "724",
	},
	{
		availability: null,
		price: 6050,
		name: "SmileVoice ONLY 500",
		allowance: "SmileVoice ONLY 500",
		validity: "90",
		code: "753",
	},
	{
		availability: null,
		price: 6600,
		name: "25GB Bigga",
		allowance: "25GB Bigga",
		validity: "30",
		code: "725",
	},
	{
		availability: null,
		price: 8800,
		name: "30GB Bigga",
		allowance: "30GB Bigga",
		validity: "30",
		code: "615",
	},
	{
		availability: null,
		price: 9900,
		name: "15GB 365",
		allowance: "15GB 365",
		validity: "365",
		code: "687",
	},
	{
		availability: null,
		price: 11000,
		name: "40GB Bigga",
		allowance: "40GB Bigga",
		validity: "30",
		code: "616",
	},
	{
		availability: null,
		price: 13200,
		name: "UnlimitedLite",
		allowance: "UnlimitedLite",
		validity: "30",
		code: "730",
	},
	{
		availability: null,
		price: 14850,
		name: "60GB Bigga",
		allowance: "60GB Bigga",
		validity: "30",
		code: "617",
	},
	{
		availability: null,
		price: 16500,
		name: "75GB Bigga",
		allowance: "75GB Bigga",
		validity: "30",
		code: "618",
	},
	{
		availability: null,
		price: 19800,
		name: "100GB Bigga",
		allowance: "100GB Bigga",
		validity: "30",
		code: "619",
	},
	{
		availability: null,
		price: 19800,
		name: "UnlimitedEssential",
		allowance: "UnlimitedEssential",
		validity: "30",
		code: "729",
	},
	{
		availability: null,
		price: 20900,
		name: "35GB 365",
		allowance: "35GB 365",
		validity: "365",
		code: "688",
	},
	{
		availability: null,
		price: 21780,
		name: "130GB Bigga",
		allowance: "130GB Bigga",
		validity: "30",
		code: "668",
	},
	{
		availability: null,
		price: 22000,
		name: "90GB Jumbo",
		allowance: "90GB Jumbo",
		validity: "60",
		code: "665",
	},
	{
		availability: null,
		price: 27500,
		name: "Freedom 3Mbps",
		allowance: "Freedom 3Mbps",
		validity: "30",
		code: "726",
	},
	{
		availability: null,
		price: 33000,
		name: "Freedom 6Mbps",
		allowance: "Freedom 6Mbps",
		validity: "30",
		code: "727",
	},
	{
		availability: null,
		price: 35200,
		name: "70GB 365",
		allowance: "70GB 365",
		validity: "365",
		code: "689",
	},
	{
		availability: null,
		price: 37400,
		name: "160GB Jumbo",
		allowance: "160GB Jumbo",
		validity: "90",
		code: "666",
	},
	{
		availability: null,
		price: 44000,
		name: "200GB Jumbo",
		allowance: "200GB Jumbo",
		validity: "120",
		code: "667",
	},
	{
		availability: null,
		price: 44000,
		name: "Freedom BestEffort",
		allowance: "Freedom BestEffort",
		validity: "30",
		code: "728",
	},
	{
		availability: null,
		price: 55000,
		name: "125GB 365",
		allowance: "125GB 365",
		validity: "365",
		code: "664",
	},
	{
		availability: null,
		price: 55000,
		name: "400GB Jumbo",
		allowance: "400GB Jumbo",
		validity: "180",
		code: "721",
	},
	{
		availability: null,
		price: 77000,
		name: "200GB 365",
		allowance: "200GB 365",
		validity: "365",
		code: "604",
	},
	{
		availability: null,
		price: 110000,
		name: "500GB 365",
		allowance: "500GB 365",
		validity: "365",
		code: "673",
	},
	{
		availability: null,
		price: 132000,
		name: "1TB 365",
		allowance: "1TB 365",
		validity: "365",
		code: "674",
	},
];
