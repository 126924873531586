import React from "react";
import { MenuHeader } from "../MenuHeader";
import { Link } from "react-router-dom";
import Background from "../../assets/Back.png";

export const Login = () => {
	return (
		<div className=' h-screen'>
			<MenuHeader check='Login' status />
			<div className='  bg-[#f9f9f9]'>
				<main
					className='h-screen'
					style={{
						backgroundImage: `url(${Background})`,
						backgroundRepeat: "no-repeat",
						backgroundPosition: "right",
						backgroundSize: "50% 100%",
					}}
				>
					<div className='px-8 py-4'>
						<div>
							<p className='inline-block p-2 bg-[#e9f4ec] rounded-md'>
								Please be sure you're are at{" "}
								<span className='underline'>https://loripay.com</span>
							</p>
						</div>
						<form
							action=''
							autoComplete='new-password'
							className='py-5 px-10  justify-center items-baseline  rounded-md bg-white inline-block my-4'
						>
							<h2 className='font-bold text-[30px] mb-4 '>
								Sign In to Loripay
							</h2>
							<small className='block my-4 text-gray-300 '>
								To Sign In, please provide the email address <br /> linked to
								your account.
							</small>
							<input
								className='block my-4 p-2 bg-[#f9f9f9] outline-none focus:outline-none rounded-md w-full'
								placeholder='email'
								type='email'
								name='email'
								id=''
								autoComplete='off'
							/>
							<input
								className='block my-4 p-2 bg-[#f9f9f9]  outline-none focus:outline-none  rounded-md w-full'
								type='password'
								name='password'
								id=''
								autoComplete='new-password'
								placeholder='password'
							/>
							<small className='block my-2'>
								Forgot Password?{" "}
								<Link to='/' className='font-bold underline'>
									Reset It
								</Link>
							</small>
							<button className='block my-6 text-center py-2 px-8 bg-[#31a64e] rounded-md'>
								Sign In
							</button>
							<small className='block my-2 text-gray-300'>
								If you do not have a loripay account, click{" "}
								<Link to='/' className='text-black font-bold'>
									here
								</Link>{" "}
								<br />
								and create an account in a few minutes
							</small>
						</form>
					</div>
				</main>
			</div>
			{/* <div className='flex justify-between items-center'>
				<section className='flex-1 p-6'>
					<p className='w-3/5 p-2 bg-[#e9f4ec] rounded-md'>
						Please be sure you're are at{" "}
						<span className='underline'>https://loripay.com</span>
					</p>
					
				</section>
				<section className='flex-1'>
					<img src={Payment} alt='' className='lg:h-[500px] rounded-full' />
				</section>
			</div> */}
		</div>
	);
};
