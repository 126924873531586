import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import {
	Confirm,
	Dashboard,
	Login,
	Pin,
	Register,
	Money,
	Pay,
	DashboardMenu,
	Electricity,
	Tv,
} from "../components";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
	},
	{
		path: "/login",
		element: <Login />,
	},

	{ path: "/register", element: <Register /> },
	{ path: "/confirm", element: <Confirm /> },
	{ path: "/pin", element: <Pin /> },
	{
		path: "/",
		element: <Dashboard />,
		children: [
			{ path: "/dashboard", element: <DashboardMenu /> },
			{ path: "/dashboard-add-money", element: <Money /> },
			{ path: "/dashboard/pay", element: <Pay /> },
			{
				path: "/dashboard/electricity",
				element: <Electricity itemToShow={"Bill"} />,
			},
			{
				path: "/dashboard/tv",
				element: <Tv itemToShow={"Bill"} />,
			},
		],
	},
]);
