import React, { useState } from "react";
import Nigeria from "../../assets/icons/019-nigeria-1.png";
import TV from "../../assets/icons/002-tv-monitor-1.png";
import Internet from "../../assets/icons/011-wifi-router.png";
import Flight from "../../assets/icons/direct-flight.png";
import ElectricityImage from "../../assets/icons/003-backup.png";
import Bet from "../../assets/icons/004-bet.png";
import { Link } from "react-router-dom";
import { Electricity } from "./Electricity";

export interface BillInterface {
	itemToShow: string;
}

export const Bill: React.FC<BillInterface> = ({ itemToShow }) => {
	const [showAll, setShowAll] = useState(true);
	const [items, setItems] = useState("");

	const checkItemsToShow = (a: string) => {
		setShowAll(false);
		setItems(a);
	};

	return (
		<main className='my-6 w-96'>
			{showAll ? (
				<section className='bg-white shadow-xl px-8 py-6 rounded-md'>
					<div className='text-center flex justify-center items-center '>
						<h2>Pay Bill</h2>
						<img src={Nigeria} className='w-4 h-4 mt-[-10px]' alt='' />
					</div>
					<div className=''>
						<Link
							to='/dashboard/tv'
							className='px-6 py-4 shadow-2xl my-3  bg-white rounded-md flex items-center justify-between'
							onClick={() => checkItemsToShow("TV")}
							state={items}
						>
							<div className='flex items-center'>
								<img src={TV} className='w-4 h-4' alt='' />
								<h2 className='ml-2'>TV</h2>
							</div>
							<div>
								<i className='fa-solid fa-chevron-right'></i>
							</div>
						</Link>
						<Link
							to='#'
							className='px-6 py-4 shadow-2xl my-3 bg-white rounded-md flex items-center justify-between'
							onClick={() => checkItemsToShow("Internet")}
							state={items}
						>
							<div className='flex items-center'>
								<img src={Internet} className='w-4 h-4' alt='' />
								<h2 className='ml-2'>Internet</h2>
							</div>
							<div>
								<i className='fa-solid fa-chevron-right'></i>
							</div>
						</Link>
						<Link
							to='/dashboard/electricity'
							className='px-6 py-4 shadow-2xl my-3 bg-white rounded-md flex items-center justify-between'
							onClick={() => checkItemsToShow("Electricity")}
							state={items}
						>
							<div className='flex items-center'>
								<img src={ElectricityImage} className='w-4 h-4' alt='' />
								<h2 className='ml-2'>Electricity</h2>
							</div>
							<div>
								<i className='fa-solid fa-chevron-right'></i>
							</div>
						</Link>
						<Link
							to='#'
							className='px-6 py-4 shadow-2xl my-3 bg-white rounded-md flex items-center justify-between'
							onClick={() => checkItemsToShow("Betting")}
						>
							<div className='flex items-center'>
								<img src={Bet} className='w-4 h-4' alt='' />
								<h2 className='ml-2'>Betting</h2>
							</div>
							<div>
								<i className='fa-solid fa-chevron-right'></i>
							</div>
						</Link>
						<Link
							to='#'
							className='px-6 py-4 shadow-2xl my-3 bg-white rounded-md flex items-center justify-between'
							onClick={() => checkItemsToShow("Flight")}
							state={items}
						>
							<div className='flex items-center'>
								<img src={Flight} className='w-4 h-4' alt='' />
								<h2 className='ml-2'>Transport and Toll</h2>
							</div>
							<div>
								<i className='fa-solid fa-chevron-right'></i>
							</div>
						</Link>
					</div>
				</section>
			) : (
				<>
					{items === "Electricity" && <Electricity itemToShow={itemToShow} />}
				</>
			)}
		</main>
	);
};
