import React, { useState } from "react";
import Logo from "../../assets/LOGO-W.png";
import { SidebarList } from "../../utils/pages";
import { Link } from "react-router-dom";
import Image from "../../assets/pexels-mikhail-nilov-7820377.jpg";

interface SidebarInterface {
	showSideBar?: boolean;
}

export const Sidebar: React.FC<SidebarInterface> = ({ showSideBar }) => {
	const [indexed, setIndexed] = useState(0);

	return (
		<>
			{showSideBar ? (
				<></>
			) : (
				<div className='bg-black px-10 py-6'>
					<div>
						<img src={Logo} className=' h-10' alt='' />
					</div>
					<div>
						<ul className='mt-6'>
							{SidebarList.map((item, index) => {
								return (
									<Link
										to='/dashboard'
										key={index}
										className={
											index === indexed
												? "my-3 text-white"
												: "my-3 text-gray-400"
										}
										onClick={() => setIndexed(index)}
									>
										<li
											className={
												item.title.toLocaleLowerCase() === "help"
													? "mt-12"
													: "my-3"
											}
										>
											<i className={`lg:w-6 lg:h-6 h-2 w-2  ${item.Icon}`}></i>
											<span className='lg:text-lg text-sm'>{item.title}</span>
										</li>
									</Link>
								);
							})}
							<li className='flex items-center absolute bottom-4 left-2'>
								<div>
									<img
										src={Image}
										className='rounded-full w-8 h-8 mr-4'
										alt=''
									/>
								</div>
								<p>
									<span className='block text-white text-xs'>Hello, Slim</span>
									<span className='block text-white text-xs'>
										emsthias33@gmail.com
									</span>
								</p>
							</li>
						</ul>
					</div>
				</div>
			)}
		</>
	);
};
