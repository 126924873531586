import "./App.css";
import { MenuHeader } from "./components/MenuHeader";
import Payment from "./assets/Image.png";
import { Services } from "./components/services/Services";
import { Footer } from "./components/Footer/Footer";

function App() {
	return (
		<div>
			<div className='App bg-[#e9f4ec] lg:h-screen'>
				<MenuHeader check='Home' status />
				<section className='lg:flex lg:justify-between block items-center justify-center p-8'>
					<div className='lg:flex-1 flex justify-center items-center flex-col '>
						<h1 className='font-bold tracking-widest'>
							<span className='lg:pb-2 pb-0 border-b-0 lg:border-b-4 lg:border-green-500'>
								ONE
							</span>{" "}
							TAP <span className='text-[#31a64e]'>PAYMENT</span>
						</h1>
						<p className='w-full lg:w-96 text-center mb-4'>
							Loripay
							<span className='text-gray-400'>
								{" "}
								is the app that seamlessly integrates bill payments within it's
								diverse features
							</span>
						</p>
						<button className='bg-[#31a64e] text-white mt-2 lg:mt-40 rounded-full py-2 px-3 text-sm flex  text-center'>
							<span>
								<i className='fa-solid fa-play'></i> |{" "}
								<i className='fa-regular fa-comment'></i>
							</span>
							<span className='ml-2'>Coming Soon</span>
						</button>
					</div>
					<div className='lg:flex-1 lg:block lg:mt-0 mt-20 justify-center items-center flex'>
						<img src={Payment} alt='' className='lg:h-[500px] h-auto' />
					</div>
				</section>
			</div>
			<div>
				<section>
					<Services />
				</section>
				<section>
					<Footer />
				</section>
			</div>
		</div>
	);
}

export default App;
