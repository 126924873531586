import React, { useState } from "react";
import { MenuHeader } from "../MenuHeader";
import OtpInput from "react-otp-input";
import Payment from "../../assets/8367360.jpg";

export const Confirm = () => {
	const [otp, setOtp] = useState("");
	return (
		<div className=' h-screen'>
			<MenuHeader check='Login' status />
			<div className='flex justify-between items-center'>
				<section className='flex-1 p-6'>
					<p className='w-3/5 p-2 bg-[#e9f4ec] rounded-md'>
						Please be sure you're are at{" "}
						<span className='underline'>https://loripay.com</span>
					</p>
					<form
						action=''
						autoComplete='new-password'
						className='py-5 px-6 flex flex-col justify-center items-baseline  rounded-md bg-[#f9f9f9] w-3/5 my-4'
					>
						<i className='fa-solid fa-mobile text-[45px]'></i>
						<h2 className='font-bold text-[22px] my-4 mb-4'>
							Confirm Loripay Account
						</h2>
						<p className='text-[14px]'>Please input OTP sent to *********12</p>
						<div className='my-8'>
							<OtpInput
								value={otp}
								onChange={setOtp}
								numInputs={6}
								// renderSeparator={<span>-</span>}
								renderInput={(props) => <input {...props} />}
								inputStyle={{
									height: "45px",
									width: "30px",
									borderRadius: "5px",
									marginLeft: "5px",
									marginRight: "5px",
									border: "0.5px #ccc solid",
									backgroundColor: "#ccc",
								}}
							/>
						</div>
						<p className='my-2 text-[14px]'>
							Resending in <span>180s</span>
						</p>
						<button className='bg-[#b0e9be] px-6 rounded-md py-[5px] my-2 text-sm'>
							Continue
						</button>
					</form>
				</section>
				<section className='flex-1'>
					<img src={Payment} alt='' className='lg:h-[500px] rounded-full' />
				</section>
			</div>
		</div>
	);
};
