import React from "react";
import etisalat from "../../assets/9mobile 1.png";
import mtn from "../../assets/MTN 2.png";
import bet9ja from "../../assets/bet9ja 2.png";
import airtel from "../../assets/Airtel 3.png";
import dstv from "../../assets/Dstv 1.png";
import glo from "../../assets/Glo 1.png";
import gotv from "../../assets/Gotv 1.png";
import ikedc from "../../assets/IKEDC 1.png";
import recharge from "../../assets/Recharge 1.png";
import showmax from "../../assets/Showmax 1.png";
import startimes from "../../assets/Startimes 1.png";
import { Powered } from "../Powered";
import CashBack from "../../assets/Cash back 1.svg";
import Secured from "../../assets/Secured 1.png";

export const Services = () => {
	return (
		<div>
			<section className='lg:flex lg:justify-between lg:items-center px-8 lg:px-0 '>
				<article className='flex-1 flex justify-center items-center'>
					<h1>OUR SERVICES</h1>
				</article>
				<article>
					<div className='grid grid-cols-6 gap-4 items-center'>
						{itemData.map((item, index) => (
							<div key={index}>
								<img
									srcSet={`${item.img}`}
									src={`${item.img}`}
									alt={item.title}
									loading='lazy'
									className=' w-14 h-14 rounded-full shadow-md'
								/>
							</div>
						))}
					</div>
				</article>
			</section>

			<section className='lg:flex lg:justify-between lg:px-10 py-4 lg:my-0 my-20 '>
				<div className='flex-1 flex-col text-center flex lg:justify-center lg:items-center'>
					<div>
						<h2 className='text-[#951D68] text-4xl font-extrabold	drop-shadow-xl'>
							Fund Your Account, <br /> Make Transfers, Pay Bills
						</h2>

						<h3 className='tracking-normal text-lg my-2 px-8'>
							Liberate your payment experience with Loripay. Load your wallet,
							buy data, pay bills and airtime hassle-free. Enjoy bonuses with
							swift bill payment - all with Loripay.
						</h3>
						<button className='py-2 px-4 text-white bg-[#951D68] rounded-md my-2'>
							Buy Now
						</button>
					</div>
				</div>

				<div className='flex-1 flex justify-center items-center lg:justify-end'>
					<img src={recharge} alt='' />
				</div>
			</section>
			<Powered />
			<section className='lg:flex lg:justify-between lg:px-10 py-4 lg:my-0 my-20 '>
				<div className='flex-1 flex justify-center items-center lg:justify-end'>
					<img src={CashBack} alt='' />
				</div>
				<div className='flex-1 flex-col text-center flex lg:justify-center lg:items-center lg:px-0 px-8'>
					<h2 className='text-green-500 font-bold text-3xl [word-spacing:5px] lg:w-3/4'>
						Enjoy upto 3% cashback on all bills, airtime and data
					</h2>
					<ul className='lg:list-disc list-none text-left leading-7 text-lg'>
						<li>
							Enjoy 3% or more for any biil, airtime, data purchase with
							Loripay! Enjoy interests on daily bill payments and unlock
							additional benefits when you choose Loripay.
						</li>
						<li>Earn right every time you spend</li>
						<li>Earn on every naira</li>
					</ul>
				</div>
			</section>
			<Powered />
			<section className='lg:flex lg:justify-between lg:px-10 py-4 lg:my-0 my-20 '>
				<div className='flex-1 flex-col  flex lg:justify-center lg:items-center lg:px-0 px-8'>
					<h2 className='text-green-500 text-left font-bold text-3xl [word-spacing:5px]'>
						Secure and Swift Transactions
					</h2>
					<ul className='lg:list-disc list-none text-left leading-7 text-lg'>
						<li>
							PCI DSS Compliance for wallet deposits ensures fast and secure
							transactions.
						</li>
						<li>Robust security layers and fraud monitoring in place</li>
						<li>Secure linking of accounts or cards</li>
						<li>Advanced Fraud Detection for enhanced security</li>
					</ul>
				</div>
				<div className='flex-1 flex justify-center items-center lg:justify-end'>
					<img src={Secured} alt='' />
				</div>
			</section>
			<Powered />
		</div>
	);
};

const itemData = [
	{
		img: etisalat,
		title: "Fern",
	},
	{
		img: mtn,
		title: "Snacks",
	},
	{
		img: startimes,
		title: "Mushrooms",
	},
	{
		img: showmax,
		title: "Tower",
	},
	{
		img: dstv,
		title: "Sea star",
	},
	{
		img: gotv,
		title: "Honey",
	},
	{
		img: ikedc,
		title: "Basketball",
	},
	{
		img: bet9ja,
		title: "Breakfast",
	},
	{
		img: glo,
		title: "Tree",
	},
	{
		img: airtel,
		title: "Burger",
	},
];
