import React from "react";

export interface PayHeaderInterface {
	itemToShow: string;
}

export const PayHeader: React.FC<PayHeaderInterface> = ({ itemToShow }) => {
	return (
		<div>
			<section>
				<h1 className='text-center text-green-500'>
					{itemToShow === "Telco"
						? "Airtime/Data"
						: itemToShow === "Bill"
						? "Pay Bill"
						: itemToShow === "Bet"
						? "Lottery Games"
						: "Send"}
				</h1>
				<div>
					<div className='grid grid-cols-3 lg:grid-cols-5 gap-3'>
						<p className='bg-white px-6 py-1 text-center flex items-center  rounded-md'>
							Send Money
						</p>
						<p
							className={
								itemToShow === "Telco"
									? "bg-white px-6 py-1 text-center flex items-center rounded-md text-green-400"
									: "bg-white px-6 py-1 text-center flex items-center rounded-md"
							}
						>
							Airtime/Data
						</p>
						<p
							className={
								itemToShow === "Bill"
									? "bg-white px-6 py-1 text-center flex items-center  rounded-md text-green-400"
									: "bg-white px-6 py-1 text-center flex items-center rounded-md"
							}
						>
							Pay A Bill
						</p>
						<p
							className={
								itemToShow === "Bet"
									? "bg-white px-6 py-1 text-center flex items-center rounded-md text-green-400"
									: "bg-white px-6 py-1 text-center flex items-center rounded-md"
							}
						>
							Betting
						</p>
						<p className='bg-white px-6 py-1 text-center flex items-center rounded-md'>
							Cardless Payments
						</p>
					</div>
				</div>
			</section>
		</div>
	);
};
