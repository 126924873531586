import React, { useEffect } from "react";
import { Icons } from "../../utils/pages";
import { Link, useOutletContext } from "react-router-dom";
import PlusButton from "../../assets/icons/add.png";

type ContextType = { setMenuName: (value: string) => void };

export const DashboardMenu = () => {
	const { setMenuName } = useMenu();

	useEffect(() => {
		setMenuName("Menu");
	});

	return (
		<div>
			<div className=''>
				<img src='' alt='' />
				<div className=''>
					<div className='flex justify-between items-center'>
						<div>
							<p className='font-bold'>Nigerian Naira</p>
							<h2 className='font-bold text-xl'>₦70,345.09</h2>
							<span className='lg:text-[#c3c3c3] text-[#eee]'>
								Auto Update, 40 Sec ago
							</span>
						</div>
						<div>
							<Link
								to='/dashboard-add-money'
								className='flex justify-between bg-white items-center px-4 rounded-md py-2'
							>
								<img src={PlusButton} className='w-4 h-4 ml-2 mr-2' alt='' />
								<span className='text-center lg:text-lg text-xs'>
									Add Money
								</span>
							</Link>
						</div>
					</div>
					<div className='flex justify-between items-center my-2'>
						<p>Recharge and Pay Bills</p>
						<p>View All</p>
					</div>
					<div className='grid grid-cols-4 gap-1 bg-white mb-4 mt-1 rounded-md py-6'>
						{Icons.map((item, index) => {
							return (
								<div key={index} className='my-4 '>
									<Link
										to={item.link}
										state={item.state}
										className='flex flex-col items-center text-xs lg:text-lg'
									>
										<img src={item.Icon} className='w-10 h-10' alt='' />
										<p className='text-center'>{item.title}</p>
									</Link>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export function useMenu() {
	return useOutletContext<ContextType>();
}
