import React from "react";
import Splash from "../../assets/splash1111 1.png";
import Payment from "../../assets/Bills Payment 1.png";
import { Powered } from "../Powered";

export const Footer = () => {
	return (
		<div className=' py-6'>
			<div className='bg-[#EEF2EF] py-6'>
				<div className='text-3xl font-bold  flex justify-center items-center my-2 py-2'>
					<h2 className='lg:w-2/4 w-full text-center'>
						We're here to assist{" "}
						<span className='text-green-500'>
							with your inquiries and expertly manage your bill payments
						</span>
					</h2>
				</div>

				<div className='lg:flex lg:justify-between lg:items-center my-12'>
					<div className='flex-1 lg:flex lg:justify-around'>
						<ul className='flex flex-col lg:block justify-center items-center'>
							<li className='font-bold text-2xl text-[#951D68] my-2'>
								Company
							</li>
							<li className='my-1'>About Us</li>
							<li className='my-1'>Contact Us</li>
							<li className='my-1'>FAQ</li>
							<li className='my-1'>Our Team</li>
						</ul>
						<ul className='flex lg:block flex-col justify-center items-center'>
							<li className='font-bold text-2xl text-[#951D68] my-2'>
								Resources
							</li>
							<li className='my-1'>Privacy Policy</li>
							<li className='my-1'>Terms and Conditions</li>
							<li className='my-1'>service Level Agreement</li>
							<li className='my-1'>Service Catalog</li>
							<li className='my-1'>Documentation</li>
							<li className='my-1'>Security</li>
						</ul>
					</div>
					<div className='flex-1  lg:flex  items-center lg:justify-end justify-center'>
						<div className='lg:block flex justify-center items-center'>
							<img src={Splash} alt='' />
						</div>
						<div className='lg:block flex justify-center items-center'>
							<img src={Payment} alt='' />
						</div>
					</div>
				</div>
			</div>

			<div className='flex justify-between items-center bg-white'>
				<div>
					<Powered />
				</div>
				<p>
					<span>Address</span> 16 Afolabi Aina Street, Ikeja Lagos
				</p>
			</div>
		</div>
	);
};
