import React, { useEffect, useState } from "react";
import { MenuHeader } from "../MenuHeader";
import Image from "../../assets/pexels-mikhail-nilov-7820377.jpg";

import Background from "../../assets/compressed.jpeg";
import { Outlet } from "react-router-dom";
import { Sidebar } from "./Sidebar";

export const Dashboard = () => {
	const [check, setCheck] = useState(true);
	const [menuName, setMenuName] = useState("");
	const [backgroundSize, setBackgroundSize] = useState("50% 100%");

	const changeStatus = () => {
		setCheck(!check);
	};

	const handleResize = () => {
		// Check the screen width and set the backgroundSize accordingly
		if (window.innerWidth < 850) {
			setBackgroundSize("100% 100%");
		} else {
			setBackgroundSize("50% 100%");
		}
	};

	useEffect(() => {
		// Add a listener for the window resize event
		window.addEventListener("resize", handleResize);
		// Initial check for the screen size
		handleResize();

		// Clean up the listener when the component unmounts
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div>
			<div className='flex '>
				<Sidebar showSideBar={check} />
				<div className='flex-1'>
					<MenuHeader
						check='Dashboard'
						country='Nigeria'
						image={Image}
						status={check}
						onChange={changeStatus}
						menuName={menuName}
					/>
					<div className='  bg-[#ebebeb] '>
						<main
							className='flex justify-center items-center h-screen lg:p-0 px-6'
							style={{
								backgroundImage: `linear-gradient(to right, rgba(195, 195,195,0.3), rgba(195,195,195,0.3)),url(${Background})`,
								backgroundRepeat: "no-repeat",
								backgroundPosition: "right",
								backgroundSize: backgroundSize,
								opacity: 0.8,
							}}
						>
							<Outlet context={{ setMenuName }} />
						</main>
					</div>
				</div>
			</div>
		</div>
	);
};
