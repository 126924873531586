import React from "react";
import { PayHeader } from "../layout/PayHeader";
import Nigeria from "../../assets/icons/019-nigeria-1.png";
import startimes from "../../assets/billers/Startimes.png";
import showmax from "../../assets/billers/Showmax.png";
import gotv from "../../assets/billers/Gotv.png";
import dstv from "../../assets/billers/Dstv.png";

export interface TvInterface {
	itemToShow: string;
}

export const Tv: React.FC<TvInterface> = ({ itemToShow }) => {
	return (
		<div>
			<PayHeader itemToShow={itemToShow} />
			<div className='flex justify-center items-center flex-col'>
				<main className='my-6  w-96 p-8 bg-white shadow-xl px-8 py-6 rounded-md'>
					<section>
						<div className='text-center flex justify-center items-center mb-8'>
							<h2>TV</h2>
							<img src={Nigeria} className='w-4 h-4 mt-[-10px]' alt='' />
						</div>
						<div>
							<p>Service Provider</p>
							<div>
								<div className='flex items-center justify-between p-4 border-b-black border-b-[0.5px]'>
									<div className='flex items-center'>
										<img src={showmax} className='w-8 h-8' alt='' />
										<span>SHOWMAX</span>
									</div>
									<div>
										<i className='fa-solid fa-chevron-right'></i>
									</div>
								</div>
								<div className='flex items-center justify-between p-4 border-b-black border-b-[0.5px]'>
									<div className='flex items-center'>
										<img src={startimes} className='w-8 h-8' alt='' />
										<span>STARTIMES</span>
									</div>
									<div>
										<i className='fa-solid fa-chevron-right'></i>
									</div>
								</div>
								<div className='flex items-center justify-between p-4 border-b-black border-b-[0.5px]'>
									<div className='flex items-center'>
										<img src={gotv} className='w-8 h-8' alt='' />
										<span>GOTV</span>
									</div>
									<div>
										<i className='fa-solid fa-chevron-right'></i>
									</div>
								</div>
								<div className='flex items-center justify-between p-4 border-b-black border-b-[0.5px]'>
									<div className='flex items-center'>
										<img src={dstv} className='w-8 h-8' alt='' />
										<span>DSTV</span>
									</div>
									<div>
										<i className='fa-solid fa-chevron-right'></i>
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
			</div>
		</div>
	);
};
